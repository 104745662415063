import * as React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { graphql, StaticQuery } from "gatsby";
import { GraphQLChildFluidImage } from "../../interfaces/graphql-image";
import { Services } from "../services";
import { StyledServicesSection, Image } from '.';

interface IProps {
  header: GraphQLChildFluidImage;
}

const ServicesSection: React.FC<IProps> = ({ header }) => {
  return (
    <StyledServicesSection>
      <Container>
        <Row className="w-100">
          <Col xs={12} md={3}>
              <Image fluid={header.childImageSharp.fluid} className="mt-2" />
          </Col>
          <Col xs={12} md={9}>
              <Row className="pb-2">
                  <Services />
              </Row>
          </Col>
        </Row>
      </Container>
    </StyledServicesSection>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        header: file(relativePath: { eq: "services-header-blue.png" }) {
          childImageSharp {
            fluid(maxWidth: 350, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => <ServicesSection header={data.header} />}
  />
);