import * as React from "react";
import { StyledLocationMap } from ".";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

interface IProps {

}

const containerStyle = {
  width: '100%',
  height: '400px',
  borderRadius: '10px',
  boxShadow: '0px 4px 25px rgb(0 0 0 / 10%), 0px -3px 25px rgb(0 0 0 / 10%)'
};

const center = {
  lat: 53.464760,
  lng: -2.308190
};

export const LocationMap: React.FC<IProps> = () => {
  return (
    <StyledLocationMap>
          <LoadScript googleMapsApiKey="AIzaSyBlJSqj5_1fWJhSxfXSYKQSfOBly33H0QA">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={14}
              options={{
                styles: [{ stylers: [{ 'saturation': -100 }] }]
            }}
            >
              <Marker position={center} />
              <></>
            </GoogleMap>
          </LoadScript>
    </StyledLocationMap>
  );
}