import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { graphql, StaticQuery } from "gatsby";
import { GraphQLChildFixedImage } from "../../interfaces/graphql-image";
import { StyledCertificationSection, Image } from ".";

interface IProps {
  psma: GraphQLChildFixedImage;
  ipaf: GraphQLChildFixedImage;
  dhf: GraphQLChildFixedImage;
  contractor: GraphQLChildFixedImage;
  safecontractor: GraphQLChildFixedImage;
  has: GraphQLChildFixedImage;

}

const CertificationsSection: React.FC<IProps> = ({ psma, ipaf, dhf, contractor, safecontractor, has }) => {
  return (
    <StyledCertificationSection>
      <Row className="w-100">
        <Col className="d-flex align-items-center justify-content-center" xs={12} md={4} lg={3} xl={2}>
          <Image fixed={psma.childImageSharp.fixed} />
        </Col>
        <Col className="d-flex align-items-center justify-content-center" xs={12} md={4} lg={3} xl={2}>
          <Image fixed={ipaf.childImageSharp.fixed} />
        </Col>
        <Col className="d-flex align-items-center justify-content-center" xs={12} md={4} lg={3} xl={2}>
          <Image fixed={dhf.childImageSharp.fixed} />
        </Col>
        <Col className="d-flex align-items-center justify-content-center" xs={12} md={4} lg={3} xl={2}>
          <Image fixed={contractor.childImageSharp.fixed} />
        </Col>
        <Col className="d-flex align-items-center justify-content-center" xs={12} md={4} lg={3} xl={2}>
          <Image fixed={safecontractor.childImageSharp.fixed} />
        </Col>
        <Col className="d-flex align-items-center justify-content-center" xs={12} md={4} lg={3} xl={2}>
          <Image fixed={has.childImageSharp.fixed} />
        </Col>
      </Row>
    </StyledCertificationSection>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        psma: file(relativePath: { eq: "certification-1.png" }) {
          childImageSharp {
            fixed(width: 200, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        ipaf: file(relativePath: { eq: "certification-2.png" }) {
          childImageSharp {
            fixed(width: 200, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        dhf: file(relativePath: { eq: "certification-3.png" }) {
          childImageSharp {
            fixed(width: 200, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        contractor: file(relativePath: { eq: "certification-4.png" }) {
          childImageSharp {
            fixed(width: 200, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        safecontractor: file(relativePath: { eq: "certification-5.png" }) {
          childImageSharp {
            fixed(width: 200, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        has: file(relativePath: { eq: "certification-6.png" }) {
          childImageSharp {
            fixed(width: 200, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => <CertificationsSection psma={data.psma} ipaf={data.ipaf} dhf={data.dhf} contractor={data.contractor} safecontractor={data.safecontractor} has={data.has} />}
  />
);