import styled from 'styled-components';
import Img from 'gatsby-image';

export const StyledServiceItem = styled.div`
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1), 0px -3px 25px rgba(0, 0, 0, 0.1);
  height: 100%;
  padding-bottom: 50px;
  a {
  	div {
      position: absolute;
      bottom: 20px;
      left: 0px;
      right: 0px;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 1140px) { 
          position: relative;
        }
  	}
	}

	@media (max-width: 768px) { 
    text-align: center;
  }

	@media (max-width: 1140px) { 
    margin-bottom: 20px;
    padding: 0px;
  }
`;

export const Image = styled(Img)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const Header = styled.span`
  text-transform: uppercase;
  font-weight: bold;

  @media (max-width: 992px) { 
      font-size: 18px;
    }
`;

export const Text = styled.p`
  font-weight: 100;
  font-size: 14px;

`;


