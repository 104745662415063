import styled from 'styled-components';

export const Item = styled.li`
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  height: 150px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1), 0px -3px 25px rgba(0, 0, 0, 0.1);

  @media (max-width: 550px) { 
    height: 175px;
}
`;

export const ItemContentWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

export const Link = styled.a`
  font-weight: bold;
  
`;

export const Header = styled.span`
  font-weight: bold;
  font-size: 16px;

  @media (max-width: 992px) { 
      font-size: 18px;
  }
`;

export const Text = styled.span`
  font-weight: 100;
  font-size: 14px;

  @media (max-width: 992px) { 
    font-size: 14px;
	}
`;

export const Img = styled.div`
  background-image: url(${(props: any) => props.img});
  // background-position: center;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;

  
  @media (max-width: 992px) { 
    background-position: left;
	}
`