import styled from 'styled-components';
import Img from 'gatsby-image';

export const StyledCertificationSection = styled.div`
  padding: 0px 30px;
  padding-bottom: 40px;
`;

export const Image = styled(Img)`
  @media (max-width: 768px) { 
    margin-bottom: 20px;
  }
`;

