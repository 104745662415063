import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { StyledAlertSection, Header, SubHeader } from '.';

interface IProps {
  header: string;
  subHeader: string;
  image: any;
}

export const AlertSection: React.FC<IProps> = ({ header, subHeader, image }) => {
  return (
    <StyledAlertSection>
      <Row className="w-100 no-gutters">
        <Col xs={8}>
          <Row>
            <Col xs={12}>
              <Header>{header}</Header>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SubHeader>{subHeader}</SubHeader>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledAlertSection>
  );
}

export default AlertSection