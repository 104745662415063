import styled from 'styled-components';
import { Link } from 'gatsby';
import { IoMdArrowDroprightCircle } from "react-icons/io";

export const StyledFooter = styled.div`
  padding: 50px 100px 0px 100px;
  background: #ff7940;
  color: white;
  
  svg {
    color: #FFFFFF;
  }
`;

export const FooterHeader = styled.span`
  font-weight: bold;
  font-size: 22px;
  padding-bottom: 40px;
  color: #FFFFFF;
  text-transform: uppercase;
`;

export const FooterLinkedInWrapper = styled.div`
  margin-top: 20px;

  @media (max-width: 768px) { 
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

export const FooterNavigation = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 15px;
`;

export const FooterNavigationItem = styled.li`
  padding-top: 15px;
`;

export const Route = styled(Link)`
  &:hover {
    color: #182A64;
    text-decoration: none;
  }
`;

export const InputWrapper = styled.div`
  width: 50%;
  background: white;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #7C7C7C;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1), 0px -3px 25px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  padding: 2px 0px;
  padding-left: 15px;
  padding-right: 5px;
  margin-top: 20px;
  
  svg {
      color: #EB5413;
  }

  @media (max-width: 1400px) { 
    width: 100%;
  }

  @media (max-width: 768px) { 
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;

export const Input = styled.input`
  border: none;
  background: transparent;
  width: 100%;
  outline: none;
`;

export const InputIcon = styled(IoMdArrowDroprightCircle)`
  margin-left: auto;
`;

export const FooterLegalWrapper = styled.div`
  padding: 30px 0px;
  text-align: center;
  border-top: 1px solid white;
  margin-top: 40px;
`;
