import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { graphql, StaticQuery } from "gatsby";
import { GraphQLChildFixedImage } from "../../interfaces/graphql-image";
import { StyledFooter, FooterHeader, FooterNavigation, FooterNavigationItem, Route, Input, InputIcon, InputWrapper, FooterLegalWrapper, FooterLinkedInWrapper } from ".";
import Img from 'gatsby-image';
import { FaLinkedin } from "react-icons/fa";

interface IProps {
  logo: GraphQLChildFixedImage;
}

const Footer: React.FC<IProps> = ({ logo }) => {
  return (
    <StyledFooter>
      <Row>
        <Col xs={{ span: 12, order: 2 }} md={{span: 3, order: 1}}>
          <Row>
            <Col xs={12}>
              <Img fixed={logo.childImageSharp.fixed} className="mt-2" />
            </Col>
            <Col className="mt-4" xs={12}>
              <span>At the forefront of Industrial Door Technology. We Service, Maintain and Install Industrial Doors.</span>
            </Col>
            <Col xs={12}>
              <FooterLinkedInWrapper>
                <a href="https://www.linkedin.com/company/inteckltd/" target="_blank" rel="noreferrer"><FaLinkedin size={30} /></a>
              </FooterLinkedInWrapper>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 12, order: 3 }} md={{span: 3, offset: 1, order: 2}}>
          <FooterHeader>Resources</FooterHeader>
          <FooterNavigation>
            <FooterNavigationItem><Route to="/">HOME</Route></FooterNavigationItem>
            <FooterNavigationItem><Route to="/about">ABOUT</Route></FooterNavigationItem>
            <FooterNavigationItem><Route to="/services">SERVICES</Route></FooterNavigationItem>
            <FooterNavigationItem><Route to="/vacancies">VACANCIES</Route></FooterNavigationItem>
            <FooterNavigationItem><Route to="/contact">CONTACT</Route></FooterNavigationItem>
          </FooterNavigation>
        </Col>
        <Col xs={{span: 12, order: 1}} md={{span: 5, order: 3}}>
          <Row>
            <Col xs={12}>
              <FooterHeader>Newsletter</FooterHeader>
            </Col>
            <Col className="mt-4" xs={12}>
              <span>Lets stay connected! We will send you information regarding any changes within IDS.</span>
            </Col>
            <Col className="mt-4" xs={12}>
              <InputWrapper>
                <Input placeholder="youremail@domain.co.uk" />
                <InputIcon size={40}/>
              </InputWrapper>
            </Col>
          </Row>  
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FooterLegalWrapper>
          <span>© Industrial Door Systems. All rights reserved. Terms of Service / Privacy Policy.</span>
          </FooterLegalWrapper>
        </Col>
      </Row>
    </StyledFooter>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "ids-logo-white.png" }) {
          childImageSharp {
            fixed(width: 150, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => <Footer logo={data.logo} />}
  />
);