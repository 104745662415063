import * as React from "react";
import { Col } from "react-bootstrap";
import { graphql, StaticQuery } from "gatsby";
import { GraphQLChildFluidImage } from "../../interfaces/graphql-image";
import { ServiceItem } from "../services-item/services-item.component";

interface IProps {
    installation: GraphQLChildFluidImage;
    repairs: GraphQLChildFluidImage;
    service: GraphQLChildFluidImage;
}

const Services: React.FC<IProps> = ({ installation, repairs, service }) => {
  return (
    <>
        <Col className="mb-2 mb-xl-0" xs={12} md={6} lg={6} xl={4}>
            <ServiceItem image={installation} header="Installation" url="">
                    Industrial Door Systems has helped many clients to secure their premises with robust, 
                    durable and reliable security door systems for a range of different environments, whatever your business or industry.  
            </ServiceItem>
        </Col>
        <Col className="mb-2 mb-xl-0" xs={12} md={6} lg={6} xl={4}>
            <ServiceItem image={repairs} header="Repairs" url="">
                    Through our wide experience Industrial Door Systems, we will be able to provide all the technical and maintenance expertise 
                    required to get your equipment moving again. 
            </ServiceItem>
        </Col>
        <Col className="mb-2 mb-xl-0" xs={12} md={6} lg={6} xl={4}> 
            <ServiceItem image={service} header="Service" url="">
                    In the event of a breakdown we recognise the importance of responding quickly. That is why our large fleet of vehicles, 
                    fully stocked with a full range of spares, are able to attend to your site the same day, anywhere in the UK. 
            </ServiceItem>
        </Col>
    </>
  );
}

export default () => (
    <StaticQuery
      query={graphql`
        query {
            installation: file(relativePath: { eq: "ids-pulling-box.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          repairs: file(relativePath: { eq: "ids-fixing-box.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          service: file(relativePath: { eq: "ids-sparks.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          },
        }
      `}
      render={(data) => <Services installation={data.installation} repairs={data.repairs} service={data.service} />}
    />
  );