import styled from 'styled-components';
import Img from 'gatsby-image';

export const StyledNewsSection = styled.div`
  padding: 100px 0px;
`;

export const Image = styled(Img)`
  max-width: 350px;
  margin-left: auto;

  @media (max-width: 992px) { 
    margin-right: auto;
    margin-bottom: 25px;
	}
`;

