import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { graphql, StaticQuery } from "gatsby";
import { GraphQLChildFluidImage } from "../../interfaces/graphql-image";
import { Button } from "../button";
import { StyledAboutUsSection, Header, Text, Image } from '.';

interface IProps {
  header: GraphQLChildFluidImage;
}

const AboutUsSection: React.FC<IProps> = ({ header }) => {
  return (
    <StyledAboutUsSection>
      <Row className="w-100">
        <Col xs={12} md={6}>
            <Image fluid={header.childImageSharp.fluid} className="mt-2" />
        </Col>
        <Col xs={12} md={6}>
            <Row className="pb-2">
                <Col xs={12}>
                    <Header>Established for over 30 years and still at the forefront of Industrial Door Technology</Header>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Text>Industrial Door Systems was founded in 1986 with the opening of the first branch in Manchester followed by Watford, West Bromwich, Bristol and Dewsbury, 
                        offering nationwide coverage and expertise to some of the UK’s largest companies.</Text>
                </Col>
            </Row>
            <Button text="Read more" url="" />
        </Col>
      </Row>
    </StyledAboutUsSection>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        header: file(relativePath: { eq: "about-us-header.png" }) {
          childImageSharp {
            fluid(maxWidth: 350, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => <AboutUsSection header={data.header} />}
  />
);