import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { graphql, StaticQuery } from "gatsby";
import Img from 'gatsby-image';
import { Header, Text, Item, List, ItemContentWrapper, Link } from '.';
import { NewsItem } from "../news-item";

interface IProps {
  data: any;
  limit: number;
}

const NewsList: React.FC<IProps> = ({ data, limit }) => {
  const { allMarkdownRemark: blogs } = data;

  return (
    <List>
      {blogs.edges.slice(0, limit).map(({ node }) => {
          const { frontmatter: blog } = node;
      
          return (
            <NewsItem thumbnail={blog.thumbnail} title={blog.title} description={blog.description} path={blog.path} />
          );
      })}
    </List>
  );
}

export default ({ limit }) => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(filter: {frontmatter: {type: {eq: "blogs"}}}, sort: { order: DESC, fields: [frontmatter___date] }) {
          edges {
            node {
              frontmatter {
                title
                description
                path
                thumbnail {
                  childImageSharp {
                    fluid(maxHeight: 300) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <NewsList data={data} limit={limit} />}
  />
);