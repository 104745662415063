import styled from 'styled-components';
import Img from 'gatsby-image';

export const StyledTitleSection = styled.div`
  padding-left: 100px;

  a {
    div {
      margin-bottom: 80px;

      @media (max-width: 992px) { 
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @media (max-width: 992px) { 
    padding-right: 100px;
    text-align: center;
  }
`;

export const Header = styled.h1`
  color: #7C7C7C;
  font-weight: bold;
  font-size: 3.5rem;
  padding: 80px 0px 20px 0px;

  @media (max-width: 1370px) { 
    font-size: 2.5rem;
  }
`;

export const SubHeader = styled.h4`
  color: #7C7C7C;
  font-weight: 100;
`;

export const Imgg = styled.div`
  background-image: url(${(props: any) => props.img});
  height: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 123%);
  background-position: bottom left;
  width: 98%;
  margin-left: auto;

  @media (max-width: 1600px) { 
    background-position-x: 27%;
    background-position-y: 110%;
  }

  @media (max-width: 1250px) { 
    background-position-x: 39%;
  }

  @media (max-width: 992px) { 
    display: none;
  }
`

export const ImgBorder = styled.div`
  background-color: #182A64;
  height: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 125%);
`