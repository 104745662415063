import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { StyledTitleSection, Header, SubHeader, IDSImage, Imgg, ImgBorder } from './';
import { graphql, StaticQuery } from "gatsby"
import { Button } from '../button';

interface IProps {
  image: GraphQLChildFluidImage;
}

const TitleSection: React.FC<IProps> = ({ image }) => {
  console.log(image.childImageSharp.fluid.src);
  return (
    <StyledTitleSection>
        <Row>
          <Col xs={12} lg={4}>
            <Row>
              <Col xs={12}>
                <Header>MANUFACTURER & MAINTENANCE OF INDUSTRIAL DOOR PRODUCTS</Header>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubHeader>At the forefront of Industrial Door Technology. Service and quality is our priority.</SubHeader>
              </Col>
            </Row>
            <Button text="Contact us" url="" />
          </Col>
          <Col xs={12} lg={8}>
            <ImgBorder>

              <Imgg img={image.childImageSharp.fluid.src} />
            </ImgBorder>
            {/* <IDSLowResImage fluid={image.childImageSharp.fluid} /> */}
          </Col>
        </Row>
    </StyledTitleSection>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "ids-building.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => <TitleSection image={data.image} />}
  />
);