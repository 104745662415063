import * as React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { graphql, StaticQuery } from "gatsby";
import { GraphQLChildFluidImage } from "../../interfaces/graphql-image";
import Img from 'gatsby-image';
import { StyledContactUsSection, Image } from ".";
import { ContactUsDetails } from "../contact-us-details";
import { LocationMap } from "../location-map";

interface IProps {
  header: GraphQLChildFluidImage;
}

const ContactUsSection: React.FC<IProps> = ({ header }) => {
  return (
    <StyledContactUsSection>
      <Container>
        <Row className="w-100">
          <Col xs={12} md={6} lg={3}>
            <Image fluid={header.childImageSharp.fluid} className="mt-2" />
          </Col>
          <Col xs={12} md={6} lg={3}>
            <ContactUsDetails />
          </Col>
          <Col xs={12} lg={6}>
            <LocationMap />
          </Col>
        </Row>
      </Container>
    </StyledContactUsSection>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        header: file(relativePath: { eq: "contact-header-blue.png" }) {
          childImageSharp {
            fluid(maxWidth: 350, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => <ContactUsSection header={data.header} />}
  />
);