import * as React from "react";
import { StyledContactUsDetails, ContactHeader, ContactInformation } from ".";
import { FaPhoneAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

interface IProps {

}

export const ContactUsDetails: React.FC<IProps> = () => {
  return (
    <StyledContactUsDetails>
      <ContactHeader>Customer Service</ContactHeader>
      <ContactInformation>
        <FaPhoneAlt />
        0800 000 0000
      </ContactInformation>
      <ContactInformation>
        <MdEmail />
        0800 000 0000
      </ContactInformation>
      <ContactHeader>Sales</ContactHeader>
      <ContactInformation>
        <FaPhoneAlt />
        0800 000 0000
      </ContactInformation>
      <ContactInformation>
        <MdEmail />
        0800 000 0000
      </ContactInformation>
    </StyledContactUsDetails>
  );
}