import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { GraphQLChildFluidImage } from "../../interfaces/graphql-image";
import { Button } from "../button";
import { StyledServiceItem, Image, Header, Text } from ".";

interface IProps {
    image: GraphQLChildFluidImage;
    header: string;
    url: string;
    children: React.ReactNode;
}

export const ServiceItem: React.FC<IProps> = ({ image, header, url, children }) => {
  return (
    <StyledServiceItem>
        <Row className="no-gutters">
            <Col xs={12}>
                <Image fluid={image.childImageSharp.fluid} />
            </Col>
        </Row>
        <Row className="p-3 no-gutters">
            <Col className="pb-3 no-gutters" xs={12}>
                <Header>{header}</Header>
            </Col>
            <Col xs={12}>
                <Text>{children}</Text>
            </Col>
        </Row>
        <Button text="Read more" url={url} />

    </StyledServiceItem>
  );
}