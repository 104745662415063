import styled from 'styled-components';
import Img from 'gatsby-image';

export const StyledServicesSection = styled.div`
  padding: 100px 0px;
  background-color: #91d4e6;
`;

export const Image = styled(Img)`
  max-width: 200px;

  @media (max-width: 992px) { 
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
  }
`;