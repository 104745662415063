import * as React from "react"
import { Layout } from "../components/layout"
// import IDS_IMAGE from '../images/ids-home.jpg'
// import IDS_LOGO from '../images/ids-logo.png'

import { Container, Row, Col } from "react-bootstrap";
import { AlertSection } from '../components/alert-section';
import { TitleSection } from '../components/title-section';
import { AboutUsSection } from "../components/about-us-section";
import { ServicesSection } from "../components/services-section";
import { NewsSection } from "../components/news-section";
import { CertificationsSection } from "../components/certifications";
import { ContactUsSection } from "../components/contact-us-section";
import { Footer } from "../components/footer";


const IndexPage = () => {
  return (
    <Layout>
      <Container fluid className="p-0">
        <TitleSection />
        <AlertSection header="Covering All Of The UK" subHeader="Industrial Door Systems are able to attend your site the same day, anywhere in the UK." image="" />
        </Container>
        <Container>
          <AboutUsSection />
        </Container>
        <Container fluid className="p-0">
          <ServicesSection />
        </Container>
        <Container fluid className="p-0">
          <AlertSection header="Cutting Edge Systems" subHeader="Specifically designed technology that provides real time information to our customers through the IDS Portal." image="" />
        </Container>
        <Container>
         <NewsSection />
        </Container>
        <Container fluid className="p-0">
          <CertificationsSection />
        </Container>
        <Container fluid className="p-0">
         <ContactUsSection />
        </Container>
        <Container fluid className="p-0">
          <Footer />
        </Container>
    </Layout>
  )
}

export default IndexPage;


