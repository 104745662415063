import styled from 'styled-components';
import { IoMdArrowDroprightCircle } from "react-icons/io";

export const StyledButton = styled.div`
  width: 180px;
  background: white;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #7C7C7C;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1), 0px -3px 25px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  padding: 2px 0px;
  padding-left: 15px;
  padding-right: 5px;
  margin-top: 20px;
  
  svg {
      color: #EB5413;
  }
`;

export const Icon = styled(IoMdArrowDroprightCircle)`
  margin-left: auto;
`;