import styled from 'styled-components';
import Img from 'gatsby-image';

export const StyledAboutUsSection = styled.div`
  padding: 100px 0px;

  a {
      div {
          position: absolute;
          bottom: 10px;
					
          @media (max-width: 768px) { 
              margin-left: auto;
              margin-right: auto;
              position: relative;
              bottom: 0px;
          }
      }
  }

  @media (max-width: 768px) { 
      text-align: center;
  }
`;

export const Header = styled.span`
  font-weight: bold;
  font-size: 22px;

  @media (max-width: 992px) { 
      font-size: 18px;
  }
`;

export const Text = styled.span`
  font-weight: 100;

  @media (max-width: 992px) { 
    font-size: 14px;
	}
`;

export const Image = styled(Img)`
  max-width: 350px;

  @media (max-width: 768px) { 
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
	}
`;

