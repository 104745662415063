import styled from 'styled-components'

export const StyledContactUsDetails = styled.div`
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 25px rgb(0 0 0 / 10%), 0px -3px 25px rgb(0 0 0 / 10%);
  padding: 20px;
`;

export const ContactHeader = styled.span`
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 40px;
`;

export const ContactInformation = styled.div`
  margin-bottom: 30px;

  svg {
    color: #EB5413;
    margin-right: 10px;
  }
`;

