import * as React from "react";
import { Link } from "gatsby";
import { StyledButton, Icon } from '.';

interface IProps {
  text: string;
  url: string;
}

export const Button: React.FC<IProps> = ({ text, url }) => {
  return (
    <Link to={url}>
      <StyledButton>
        {text}
        <Icon size={40}/>
      </StyledButton>
    </Link>
  );
}

export default Button;