import * as React from "react";
import { GraphQLChildFluidImage } from "../../interfaces/graphql-image";
import { Header, Text, Item, ItemContentWrapper, Link, Img } from '.';
import { Row, Col } from "react-bootstrap";

interface IProps {
  thumbnail: GraphQLChildFluidImage;
  title: string;
  description: string;
  path: string;
}

export const NewsItem: React.FC<IProps> = ({ thumbnail, title, description, path }) => {

  return (
    <Item>
      <Row>
        <Col xs={5}>
        <Img img={thumbnail.childImageSharp.fluid.src} />

        </Col>
        <Col xs={7}>
        <ItemContentWrapper>
          <Header>{title}</Header>
          <Text>{description} <Link href={path}>Read more ...</Link></Text>
        </ItemContentWrapper>
        </Col>
      </Row>


    </Item>
  );
}