import styled from 'styled-components';

export const StyledAlertSection = styled.div`
  background-color: #ff7940;
  padding: 40px 100px;
`;

export const Header = styled.span`
  font-size: 3.5rem;
  color: white;
  font-weight: 600;
  text-transform: uppercase;

  @media (max-width: 1370px) { 
    font-size: 2.5rem;
  }
`;

export const SubHeader = styled.span`
  color: white;
  font-weight: 100;
  font-size: 20px;
`;

