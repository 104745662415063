import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { graphql, StaticQuery } from "gatsby";
import { GraphQLChildFluidImage } from "../../interfaces/graphql-image";
import { NewsList } from '../news-list';
import { StyledNewsSection, Image } from '.';

interface IProps {
  header: GraphQLChildFluidImage;
}

const NewsSection: React.FC<IProps> = ({ header }) => {
  return (
    <StyledNewsSection>
      <Row className="w-100">
        <Col xs={{span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
          <NewsList limit={2} />
        </Col>
        <Col xs={{span: 12, order: 1 }} lg={{ span: 6, order: 2 }}>
            <Image fluid={header.childImageSharp.fluid} />
        </Col>
      </Row>
    </StyledNewsSection>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        header: file(relativePath: { eq: "news-header.png" }) {
          childImageSharp {
            fluid(maxWidth: 350, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => <NewsSection header={data.header} />}
  />
);